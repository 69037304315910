import Link from 'next/link';
import React from 'react';

import AppleSymbol from './icons/AppleSymbol';
import GoogleSymbol from './icons/GoogleLogo';
import LoadingSpinner from './loaders/LoadingSpinner';

type ButtonProps = {
  elementId?: string;
  children: React.ReactNode;
  className?: string;
  isSmall?: boolean;
  isInverted?: boolean;
  buttonStyle: 'primary' | 'secondary';
  disabled?: boolean;
  onClick?: () => void;
  link?: string;
  storeType?: 'apple' | 'google';
  buttonType?: 'submit' | 'button';
  isLoading?: boolean;
};

const Button = ({
  elementId,
  className,
  children,
  buttonStyle,
  disabled,
  onClick,
  link,
  storeType,
  buttonType,
  isLoading,
}: ButtonProps) => {
  // Set the button styles based on the buttonStyle prop
  const buttonStyles =
    {
      primary:
        'bg-secondary-blue-400 border-secondary-blue-400 hover:bg-secondary-blue-500 hover:border-secondary-blue-500 text-action-content-fill-primary-default',
      secondary:
        'bg-transparent text-grey-45 border-grey-65 hover:bg-grey-95 hover:text-grey-30 hover:border-grey-45',
    }[buttonStyle] ||
    'bg-secondary-blue-400 border-secondary-blue-400 text-action-content-fill-primary-default';

  // Set the button common classes, shared by all button styles
  const commonClasses = `flex justify-center p-3 w-full rounded-s border disabled:bg-grey-80 disabled:border-grey-80 disabled:cursor-not-allowed ${
    isLoading && 'cursor-wait'
  } ${buttonStyles} ${className ? className : ''}`;

  // Set the button content based on loading state, add store icons if storeType is set
  const content = isLoading ? (
    <LoadingSpinner className="h-6 w-6" />
  ) : (
    <span
      className={`whitespace-nowrap text-sb16
      ${storeType ? 'flex items-center justify-center' : ''}`}
    >
      {storeType === 'apple' && (
        <AppleSymbol
          className={`mr-2 h-5 w-5 ${
            buttonStyle === 'primary'
              ? 'text-action-content-fill-primary-default'
              : 'text-icon-fill-secondary-enabled'
          }`}
        />
      )}
      {storeType === 'google' && <GoogleSymbol className="mr-2 h-5 w-5" />}
      {children}
    </span>
  );

  // Return a next link or a simple button based on the link prop
  return link ? (
    <Link
      id={elementId}
      href={link}
      className={commonClasses}
      onClick={onClick}
    >
      {content}
    </Link>
  ) : (
    <button
      type={buttonType}
      disabled={disabled}
      id={elementId}
      className={commonClasses}
      onClick={onClick}
    >
      {content}
    </button>
  );
};

export default Button;
