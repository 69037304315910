import React from 'react';

import { Icon } from '@/components/ui/icons/types';

const CheckScribble = ({ className }: Icon) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#circle)">
        <rect x="26" y="26" width="98" height="98" fill="#D9B6FD" />
      </g>
      <path
        d="M89.5426 71.4785C91.9454 67.4574 106.941 45.8013 109.185 41.9622C110.781 39.2236 112.285 36.4469 113.818 33.6787C114.106 33.1623 114.409 32.6121 115.11 32.5401C115.937 32.4555 116.014 33.1666 116.369 33.6068C117.677 35.2448 117.84 36.9083 116.73 38.6776C112.785 44.9632 96.8902 69.4468 92.1904 75.3599C90.2923 77.7472 88.4181 77.7937 86.5632 75.4361C83.5261 71.5716 73.0106 53.0543 70.9875 48.7115C70.7472 48.1908 70.4301 47.6194 70.8481 47.1284C71.2566 46.6501 71.8813 47.0861 72.4147 47.0776C74.7406 47.0395 75.9131 48.2162 76.7781 49.9728C77.9795 52.4151 87.5916 69.0743 89.5426 71.47V71.4785Z"
        fill="#262626"
      />
      <defs>
        <clipPath id="circle">
          <rect x="26" y="26" width="98" height="98" rx="49" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckScribble;
