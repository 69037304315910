'use client';

import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';

import { loadingSpinner } from '@/components/ui/loaders/loading-spinner';

export default function LoadingSpinner({ className }: { className?: string }) {
  const animationContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (animationContainer.current) {
      const animation = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingSpinner,
      });
      return () => {
        animation.destroy();
      };
    }
  }, []);

  return (
    <div className={`${className}`}>
      <div ref={animationContainer} />
    </div>
  );
}
