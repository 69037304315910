import React from 'react';

import { Icon } from '@/components/ui/icons/types';

const AppleSymbol = ({ className }: Icon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.0039 7.65962C19.8821 7.75412 17.7315 8.96595 17.7315 11.6605C17.7315 14.7772 20.468 15.8798 20.55 15.9071C20.5374 15.9743 20.1152 17.4172 19.1071 18.8873C18.2082 20.1811 17.2694 21.4727 15.8413 21.4727C14.4131 21.4727 14.0456 20.6431 12.3969 20.6431C10.7903 20.6431 10.219 21.5 8.91269 21.5C7.60636 21.5 6.69487 20.3029 5.64687 18.8327C4.43295 17.1064 3.45215 14.4244 3.45215 11.8789C3.45215 7.79613 6.10681 5.63081 8.71947 5.63081C10.1077 5.63081 11.2649 6.5423 12.1365 6.5423C12.9661 6.5423 14.2598 5.57621 15.8392 5.57621C16.4377 5.57621 18.5884 5.63081 20.0039 7.65962ZM15.0894 3.84773C15.7426 3.07276 16.2046 1.99745 16.2046 0.922142C16.2046 0.773027 16.192 0.621812 16.1647 0.5C15.102 0.539904 13.8377 1.20777 13.0753 2.09196C12.4768 2.77243 11.9181 3.84773 11.9181 4.93774C11.9181 5.10156 11.9454 5.26538 11.958 5.31788C12.0252 5.33048 12.1344 5.34518 12.2436 5.34518C13.1971 5.34518 14.3963 4.70672 15.0894 3.84773Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AppleSymbol;
