import React from 'react';

import { Icon } from '@/components/ui/icons/types';

const ErrorOmino = ({ className }: Icon) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.543 25.3516L85.543 25.3516V51.2149L103.827 32.9268L117.969 47.0719L99.6851 65.36H125.543V85.3643H99.6851L117.969 103.652L103.827 117.798L85.543 99.5092L85.543 125.373H65.543L65.543 99.5092L47.2585 117.798L33.1165 103.653L51.401 85.3643L25.543 85.3643V65.36L51.401 65.36L33.1165 47.0719L47.2585 32.9267L65.543 51.2149V25.3516Z"
        fill="#FF7A00"
      />
      <path
        d="M81.5794 99.0653C81.8512 99.2544 83.361 100.69 83.1543 101.031C82.9517 101.367 81.9238 101.029 81.5669 100.978C80.6596 100.851 79.9575 100.279 79.149 99.9417C76.7832 98.9529 74.3402 98.4292 71.7573 98.5768C70.5788 98.6438 69.3972 98.6185 68.2218 98.7778C67.5281 98.8715 67.3767 98.6331 67.6235 98.0078C67.8061 97.5448 68.1186 97.3176 68.5904 97.1566C70.8236 96.399 73.0781 96.4835 75.3611 96.7666C76.8116 96.9467 78.181 97.3999 79.5208 97.9116C80.2567 98.1927 81.0274 98.5175 81.5808 99.0652L81.5794 99.0653Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="2.43954"
      />
      <path
        d="M90.9164 83.3059C92.2338 82.9685 93.6822 83.1234 94.8072 82.0668C96.1358 80.82 96.2569 79.2648 94.9878 77.9495C94.4737 77.4157 94.0809 76.7892 93.4277 76.3593C90.6472 74.5279 87.1914 76.9801 86.9136 79.6749C86.7908 80.8689 88.2378 82.6141 89.7282 83.0903C90.1081 83.2117 90.4945 83.3507 90.918 83.3075L90.9164 83.3059Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="1.21977"
      />
      <path
        d="M60.5621 83.3059C61.8796 82.9685 63.328 83.1234 64.4529 82.0668C65.7816 80.82 65.9027 79.2648 64.6336 77.9495C64.1195 77.4157 63.7266 76.7892 63.0735 76.3593C60.2929 74.5279 56.8371 76.9801 56.5593 79.6749C56.4366 80.8689 57.8835 82.6141 59.374 83.0903C59.7539 83.2117 60.1402 83.3507 60.5638 83.3075L60.5621 83.3059Z"
        fill="#262626"
        stroke="#262626"
        strokeWidth="1.21977"
      />
      <path
        d="M78.9194 91.594C77.062 90.8895 76.1872 90.1072 76.0919 89.2543C75.7353 85.9895 75.8846 82.7232 75.9965 79.4584C76.0173 78.9064 76.0919 78.3558 76.1375 77.8052C76.1748 77.3392 76.7055 76.9647 77.9244 76.7164C78.4302 76.6138 78.8075 76.3184 79.4418 76.446C80.0181 76.5611 80.0222 76.844 80.0015 77.0812C79.9352 77.8135 79.8481 78.5458 79.8315 79.2767C79.7445 82.941 79.6698 86.6053 79.6159 90.2709C79.6077 90.705 79.5123 91.1239 78.9194 91.5954L78.9194 91.594Z"
        fill="#262626"
      />
      <path
        d="M71.7285 90.5076C72.0807 88.6498 72.4717 87.7748 72.8981 87.6794C74.5302 87.3228 76.163 87.4721 77.795 87.5841C78.071 87.6048 78.3462 87.6794 78.6215 87.7251C78.8544 87.7624 79.0416 88.2932 79.1657 89.5123C79.217 90.0182 79.3647 90.3956 79.3009 91.0301C79.2434 91.6065 79.102 91.6106 78.9834 91.5899C78.6173 91.5235 78.2512 91.4365 77.8859 91.4199C76.0541 91.3328 74.2224 91.2581 72.3899 91.2042C72.1729 91.1959 71.9635 91.1006 71.7278 90.5076H71.7285Z"
        fill="#262626"
      />
    </svg>
  );
};

export default ErrorOmino;
