import React from 'react';

import { Icon } from '@/components/ui/icons/types';

const GoogleSymbol = ({ className }: Icon) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.1926 12.2151C21.1926 11.5774 21.1409 10.9364 21.0306 10.3091H12.1831V13.9211H17.2497C17.0394 15.0861 16.3639 16.1167 15.3747 16.7715V19.1152H18.3974C20.1724 17.4815 21.1926 15.0689 21.1926 12.2151Z"
        fill="#4285F4"
      />
      <path
        d="M12.1835 21.3799C14.7133 21.3799 16.8468 20.5493 18.4012 19.1155L15.3785 16.7718C14.5375 17.3439 13.4518 17.6679 12.1869 17.6679C9.73981 17.6679 7.66494 16.017 6.92047 13.7974H3.80127V16.2134C5.39361 19.3809 8.63689 21.3799 12.1835 21.3799Z"
        fill="#34A853"
      />
      <path
        d="M6.91677 13.797C6.52386 12.6321 6.52386 11.3706 6.91677 10.2056V7.78955H3.80102C2.47062 10.44 2.47062 13.5627 3.80102 16.2131L6.91677 13.797Z"
        fill="#FBBC04"
      />
      <path
        d="M12.1835 6.33179C13.5208 6.31111 14.8132 6.81432 15.7818 7.73802L18.4598 5.05999C16.764 3.46764 14.5134 2.5922 12.1835 2.61977C8.63689 2.61977 5.39361 4.61882 3.80127 7.78972L6.91702 10.2058C7.65805 7.98273 9.73636 6.33179 12.1835 6.33179Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default GoogleSymbol;
