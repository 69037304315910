export const CSRF_PROTECTION_HEADER_NAME = 'x-evernote-anti-csrf';
export const CSRF_PROTECTION_HEADER_VALUE = '.'; // we don't really use the value

export const EMAIL_SESSION_HEADER = 'x-evernote-email';

export const ZENDESK_URL =
  'https://help.evernote.com/hc/requests/new?ticket_form_id=30415233388691&guest=true';

export enum PasswordSafety {
  OK,
  INVALID_LENGTH,
  CONTAINS_COMMON_WORDS,
  LEAKED,
}

export const SESSION_STORAGE_KEYS = {
  SAML_REDIRECT_URL: 'saml-redirect-url',
  EMAIL: 'email', // legacy inherited name. Since we store also "usernames" we may consider to change this value
};

export const LOCAL_STORAGE_ID_COOKIE_NAME = 'evernote-local-storage-id';
export const REDIRECT_TO_COOKIE_NAME = 'evernote-redirect-to';

export const SETTINGS_ERRORS_QUERY_PARAMETERS = {
  THREE_DS_FAILURE: '3ds-failure',
} as const;

// The HTTP header name used to carry the UserService-issued JWT token for authentication.
export const AUTH_TOKEN_HEADER_NAME = 'evernote-auth';
