export const loadingSpinner = {
  nm: 'newScene',
  ddd: 0,
  h: 600,
  w: 600,
  meta: { g: '@lottiefiles/toolkit-js 0.33.2' },
  layers: [
    {
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      st: 0,
      op: 900.000036657751,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [244, 244, 100] },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [300, 300, 0] },
        r: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
      },
      ef: [],
      shapes: [
        {
          ty: 'el',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Shape - Ellipse',
          nm: 'Ellipse Path 1',
          d: 1,
          p: { a: 0, k: [0, 0] },
          s: { a: 0, k: [100, 100] },
        },
        {
          ty: 'tm',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Filter - Trim',
          nm: 'Trim Paths 1',
          ix: 2,
          e: {
            a: 1,
            k: [
              {
                o: { x: 0.561, y: 0.016 },
                i: { x: 0.439, y: 1.017 },
                s: [100],
                t: 0,
              },
              { s: [0], t: 30.0000012219251 },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.561, y: 0.015 },
                i: { x: 0.439, y: 1.016 },
                s: [100],
                t: 5,
              },
              { s: [0], t: 33.0000013441176 },
            ],
            ix: 1,
          },
          m: 1,
        },
        {
          ty: 'st',
          bm: 0,
          hd: false,
          mn: 'ADBE Vector Graphic - Stroke',
          nm: 'Stroke 1',
          lc: 2,
          lj: 1,
          ml: 4,
          o: { a: 0, k: 100 },
          w: { a: 0, k: 16 },
          c: { a: 0, k: [1, 1, 1] },
        },
      ],
      ind: 1,
    },
  ],
  v: '4.5.3',
  fr: 29.9700012207031,
  op: 33.0000013441175,
  ip: 1.00000004073083,
  assets: [],
};
